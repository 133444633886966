:root{
  --themeclr: #001489;
  --themegrad: linear-gradient(90deg, #957bfe 22%, #c49efb 70%, #91d3f3 105%);
  --aqua: #47bfa2
}
@font-face {
  font-family: "maka";
  src: url(../src/Assets/lang/Mako-Bold.otf);
}
.headfont {
  font-family: "maka";
}
.togglebtn .fa {
  color: #8e8e8e;
}

.poppins{
  font-family: 'Poppins';
}
.login {
  background: #030303;
}
.login .col-md-4 .logins{
  background: linear-gradient(180deg, #21202a, #21202a);
}
.login .container .row {
  min-height: 100vh;
}
body{
  color: #fff;
}
.w-full{
  width: 100%;
}
.themeclr{
  color:#3a56f8
}
.textaqua{
  color: var(--aqua);
}
.fw-700{
  font-weight: 700;
}
.upper{
  text-transform: uppercase;
}
.nounder{
  text-decoration: none;
}
.container-theme{
  /* width: 95%; */
}
.transscroll .btn {
  font-weight: 700;
  text-transform: capitalize !important;
}
.ciclechart {
  background-image:conic-gradient(#dedcdc 0 118deg, #4a4ce4 10deg 90deg);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
}
.ciclechart.violet{
  background-image: conic-gradient(#dedcdc 0 180deg, #c999fc 10deg 90deg);
}
.dashboard .page{
  padding-top: 55px;
}
.portscroll{
  height: 300px;
    overflow-y: auto;
}
.potfolio {
  position: relative;
}
.button:focus{
  box-shadow: none;
}
.br-10.input-group input {
  border-radius: 10px;
}
.br-10.input-group .input-group-text{
  border-radius: 10px;
}
.viewmore {
  min-width: 40px !important;
  width: 40px !important;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
}
.viewmore span {
  font-size: 18px;
  color: #fff;
  margin-top: 3px;
}
.ciclechart p {
  position: absolute;
  height: 70px;
  width: 70px;
  background: #1a1a1a;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}
.tablescroll{
    overflow-y: auto;
    max-height: 242px;
    height: 78%;
}
.notification .d-flex img {
  width: 50px;
  height: 50px;
  border-radius: 7px;
  margin-right: 5px;
}
.logininput input, .logininput button, .logininput span {
  background: #2c2b2b !important;
  border-color: #2c2b2b;
  color: #fff;
}
.logininput input::placeholder , .logininput input:focus{
  color: #fff !important;
}
.logininput span{
  color:#fff
}
#pass.password.input-group input.form-control {
  margin-right: 0 !important;
  border-radius: 8px 0 0 8px !important;
}  
#pass.password button {
   background: #2c2b2b;
border-color: #2c2b2b !important;
border-radius: 0 6px 6px 0;  } 
.profbox{
  width: 155px;
  margin: auto;
}
.colorcards  > div {
  background-size: cover;
  background-repeat: no-repeat;
  height: 190px;
  border-radius: 8px;
  padding: 15px;
}
.notify hr{
  border-color: #ffffff57;
}
.trunkon200{
  max-width: 200px;
  white-space: break-spaces;
  word-break: break-all;
}
.docimg {
  max-width: 200px;
}
.notify .themescroll {
  background: #202020;
  height: 250px;
  overflow-x: auto;
}
.notify .dropdown-item:hover {
  background-color: #000000;
}
.notify button{
  background: transparent !important;
  border-color: transparent !important;
}
.notify button:after{
  display: none;
}
.notify button:focus{
  box-shadow: none !important;
}
.assets .rdt_TableCell[data-column-id="3"] div {
  white-space: nowrap !important;
}
.purplecard {
  background-image: url(../src/Assets/Images/purple.png);
}
.yellowcard{
  background-image: url(../src/Assets/Images/yellow.png);
}
.purple2card{
  background-image: url(../src/Assets/Images/purple2.png);
}
.aquacard{
  background-image: url(../src/Assets/Images/aqua.png);
}
.colorcards {
  height: 267px;
  overflow: auto;
}
.rdt_TableCol div, .rdt_TableCell div {
  overflow: visible !important;
  white-space: break-spaces !important;
}
/* .rdt_TableCol {
  min-width: 200px !important;
} */

.lightgreen{
  color: greenyellow;
}
.colorcards > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.coinimg {
  height: 35px;
}
.mw-120{
  max-width: 120px;
}
.br-0,.btn.br-0.nav-link, .btn.br-0.btn-theme{
  border-radius: 0 !important;
}
.br-15 {
  border-radius: 15px;
}
.h-100{
  height: 100%;
}
.br-10 {
  border-radius: 10px;
}
.br-6 {
  border-radius: 6px;
}
.fw-300{
  font-weight: 300;
}
select.form-select option {
  background: #050505;
}
.show.dropdown > div {
  /* background-color: #202020 !important; */
}
.show.dropdown > div a{
  color: #fff;
}
button#pagination-previous-page,
button#pagination-next-page {
  background: var(--themegrad);
  border-radius: 0;
  color: #000;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0 !important;
  height: 30px;
  border-radius: 7px; margin-left: 5px;
  width: 83px !important;
}
.rdt_Pagination > div > div {
  display: none;
}
#pagination-previous-page[disabled],
#pagination-next-page[disabled]{
  background:#313131  !important;
  color: #fff;
}
#pagination-first-page,
#pagination-last-page{
  display: none;
}
.seeall a{
  color: gray !important;
}

.seeall a:hover{
  background-color: transparent !important;
}
.show.dropdown > div a:hover{
  /* background-color: #000000; */
}
.show>.btn-success.dropdown-toggle:focus{
  box-shadow: none !important;
}
.show.dropdown > div.themescroll a:hover{
  background-color: #000000;
}
li.nav-item button {
  min-width: 100px;
}
.wizardinput input:focus, select:focus, .themeinput textarea:focus {
  background-color: #3f3f3f !important ;
  box-shadow: none !important;
}
.wizardinput input{
  color: #fff !important;

}
.themeinput select option,.themeinput select:focus, .themeinput textarea:focus{
  color:#fff
}
.flex-1 > *{
  flex: 1;
}
.fa.fa-eye-slash:hover,.fa.fa-eye:hover{
  cursor: pointer
}
.themeinput input.form-control {
  background-color: #2c2b2b;
  border-color: #2c2b2b;
  border-radius: 8px!important;
  color: #fff;
  height: 45px;
  margin-right: 10px;
  /* appearance: auto; */
}
.themeinput select {
  background-color: #d7d7d7;
    border-color: #d7d7d7;
    border-radius: 8px!important;
    color: #000;
  height: 45px;
  margin-right: 10px;
 filter: invert(1);
}
select.form-select:focus {
  background-color: #c5c5c5 !important;
  color: #000;
  border-color: #c5c5c5 !important;
}
.themeinput textarea {
  background: #2c2b2b;
  border-color: #2c2b2b;
  border-radius: 8px!important;
  color: #fff;
  margin-right: 10px;
}
.details .d-flex p:first-child,.details .d-sm-flex p:first-child {
  max-width: 149px;
  min-width: 149px;
}
.details .d-flex p:last-child {
  text-align: left;
}
.currencyicon img {
  max-width: 100px;
}
.choosebtn{
  position: relative;
}
.choosebtn input {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.graybg.balance {
  padding: 15px;
  background: #424242;
  text-align: center;
  margin-top: 19px;
}
.txtyellow{
  color: yellow;
}
.f-14{
  font-size: 14px;
}
.f-12{
  font-size: 12px;
}
.d-flex.flex-1 > div {
  flex: 1;
}
.jc-between{
  justify-content:  space-between;
}
.jc-center{
  justify-content: center;
}
.page{
  display: flex;
  width: 100%;
  /* padding-top: 50px; */
}
.form-check-input:checked {
  background-color: #7f838900;
  border-color: #ababab;
}
.form-check-input {
  background-color: #fff0;
  border-color: gray !important;
}
.dashboard nav.navbar, nav.navbar {
  position: fixed;
  /* height: 45px; */
  width: 100%;
  z-index: 999;
}
.page .sidebar {
  width: 210px;
  background-color: #1a1a1a;
  height: calc(100vh - 55px);
  position: fixed;
  overflow-y: auto;
}

 .sidebarlist li a , .sidebarlist li .dropdown{
  text-align: left;
  /* height: 46px; */
  /* margin-bottom: 1px; */
  /* padding-left: 15px; */
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 9;
}
.profile .input-group input {
  background: transparent;
  border-right: 0;
  color: #fff;
}
.coininput{
  max-width: 120px;
}
.crypt img {
  width: 30px;
}
.profile .input-group .input-group-text{
   background-color: transparent;
   border-left: 0;
   color: gray;
}
.selectbank button, .selectbank button:hover,
.selectbank button:focus,.selectbank button:active,
.selectbank .show>.btn-primary.dropdown-toggle {
  background: #fff;
  color: #000;
  box-shadow: none;
}


.sidebar ul li:hover button img,
.sidebar ul li a.active button img{
  filter: invert(0);
}

.sidebarlist li a:after {
  background: transparent;
}
.sidebar ul li button{
  background: transparent;
  border: 0;
  color: #fff;
  width: 100%;
    text-align: left;
}
.sidebar ul li .dropdown{
  /* padding: 0;
  height: 46px; */
  /* padding-left: 15px; */
  padding-top: 0;
    padding-bottom: 0;
}
.sidebar ul li .dropdown .dropdown-toggle:after {
  display: none;
  /* display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; */
}
.sidebar ul li  .dropdown button{
  /* height: 46px; */
  padding-left: 20px;
  background: transparent;
 border-radius: 0;
}
.sidebar ul li{
  margin-bottom: 5px;
  /* margin-top: 16px; */
  list-style-type: none;
}
.show>.btn-success.dropdown-toggle{
  background-color: transparent;
  border: 0;
}
.sidebar ul li button .faqq , .mobilesidebar button img.faqq{
  height: 29px;
}
.sidebar ul li button img , .mobilesidebar button img{
  width: 29px;
  margin-right: 10px;
  /* filter: invert(0.4); */
}
.tabtable.potfolio button.btn.btn-outline-success {
  color: #65a819;
  border-color: #65a819;
}
.tabtable.potfolio button.btn.btn-outline-success:hover {
  background-color: #65a819;
  color: #fff;
}
.notification .d-flex img {
  width: 50px;
  height: 50px;
}
.sidebarlist li:hover > a,.sidebarlist li:hover a.active:after,
.sidebarlist li:hover a:after {
  background:linear-gradient(90deg, #302954 51%, #5a595e 81%, #FFFFFF 120%) 0% 0% no-repeat padding-box !important;
}
.sidebarlist .show.dropdown button:focus{
  background:linear-gradient(90deg, #302954 51%, #5a595e 81%, #FFFFFF 120%) 0% 0% no-repeat padding-box !important;


}
/* .sidebarlist li > a, */
.sidebarlist li:hover .dropdown button {
  background:linear-gradient(90deg, #302954 51%, #5a595e 81%, #FFFFFF 120%) 0% 0% no-repeat padding-box !important;
}
.sidebar ul li.active, .sidebarlist li > a.active {
  background:linear-gradient(90deg, #302954 51%, #5a595e 81%, #FFFFFF 120%) 0% 0% no-repeat padding-box !important;
}
.showhead{
  display: none;
}
.navbar-brand{
  width: 210px;
    background: #1a1a1a;
  margin-right: 0;
}
.content .nav.nav-tabs {
  background: #030303;
  border-bottom: 0;
}
.nav.nav-tabs li.nav-item:first-child button {
  border-radius: 13px 0 0 0;
}
.nav.nav-tabs li.nav-item:last-child button{
  border-radius:  0  13px 0 0;
}
.content .nav-item button {
  background: #1a1a1a;
}
.navbar-collapse.collapse, .dashboard nav.navbar {
  background: #050505 !important;
}
.page .content{
  width: calc(100% - 200px);
  min-height: 100vh;
  background: #030303;
  padding-left: 200px;
  width: 100%;
}
.conts {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box {
  background: linear-gradient(180deg, #21202a, #21202a);
  padding:35px 15px 15px 15px;
  /* margin: 10px; */
  /* aspect-ratio: 2.5/1; */
}
.box.darkbox{
  background: #1a1a1a; 
}
.mobileicons{
  display: none !important;
}
.hoverbox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.dashbox .hoverbox img {
  height: 60px;
  width: 60px;
  position: absolute;
  top: -22px;
  left: 0;
  right: 0;
  margin: auto;
}

.hoverbox:hover {
  background: linear-gradient(90deg, #4a34cf, #4a34cf);
}
.hoverbox button:focus {
  box-shadow: none;
}
.chartbox canvas {
  /* height: 400px !important; */
  width: 400px !important;
}

.bal {
  background: #2a2a2a;
  font-size: 14px;
  padding: 6px 18px;
  max-width: 195px;
  text-align: center;
  height: 35px;
  border-radius: 5px;
}
.headdrop >div {
  background: #202020;
  min-width: 90px !important;
}
.headdrop > div a{
  color: #fff;
}
.headdrop a:hover{
  background:transparent;
  color: #fff;
}
.noshrink {
  flex-shrink: 0;
}
.noshadow input:focus{
  box-shadow: none;
}
.btn.btn-theme {
  background: linear-gradient(90deg, #957bfe 22%, #c49efb 70%, #91d3f3 105%);
  border-radius: 8px !important;
  border: none;
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  padding: 6px 16px;
  font-family: "poppins";
}
.dash-bord {
  border: 1px dashed gray;
  padding: 17px;
  margin-bottom: 20px;
  background: #111111;
}
li.nav-item button, li.nav-item button:hover{
  border: 0 !important;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "maka";
}
li.nav-item button.active {
  background: var(--themegrad) !important;
  border: 0;
  color: #000 !important;
}
.redeem img {
  max-height: 135px;
}
.redeem img.chair {
  max-height: 225px !important;
}
.mw-300{
  max-width: 300px !important;
}
.zarctech .owl-dots {
  text-align: left !important;
}
.btn.btn-theme.small{
  padding: 4px 12px;
  font-size: 12px;
}

.tabtable .rdt_TableCell, .tabtable .rdt_TableCol_Sortable {
  font-size: 18px !important;
}
.acquire button.btn-link.small {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1;
}
.wizardinput select {
  background: #191919;
  border: none;
  border-bottom: 1px solid #191919;
  appearance: auto;
  color: #fff;
  font-size: 14px;
}
.rightlabel span {
  position: absolute;
  right: 10px;
  top: 8px;
  bottom: 0;
  margin: auto;
}
.rightlabel  {
  position: relative;
}
input[type="text"], select {
  height: 42px;
}
.wizardinput input {
  background: #191919;
  border: none;
}
.selectbox button{
  position: absolute;
  right: 0;
}
.selectbox button:focus{
  box-shadow: none;
}
.selectbox p {
  position: absolute;
  font-size: 10px;
  left: 13px;
  color: #a8a8a8;
  top: 5px;
}
.wizardinput .form-check input {
  background-color: #686868;
  border: 2px solid white !important;
}
.selecttoken {
  max-width: 122px;
  background: #686868;
  font-size: 14px;
  font-family: 'maka';
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 34px;
}
.selectbox {
  background: #2c2b2b;
  height: 44px;
  border-radius: 4px;
}
.selectbox span {
  padding-left: 12px;
  color: #a8a8a8;
}
.wizardinput .input-group {
  border-bottom: 1px solid #262626;
  padding-bottom: 10px;
}
.back-btn {
  background: #212121;
  height: 18px;
  width: 18px;
  font-size: 11px;
  padding: 0;
}
select.form-select:focus {
  box-shadow: none;
  
}
.btn.btn-theme:hover{
  background: linear-gradient(90deg,  #91d3f3 28% ,  #c49efb 64%,#957bfe 86%);
}
.tabtable .tab-content {
  height: 462px;
  overflow-y: auto;
  padding: 17px;
}
.tabtable.potfolio.nohead .rdt_TableHeadRow {
  display: none;
}
.editprofbtn {
  position: absolute;
    right: 0;
    bottom: 0;
}
.rdt_Pagination span {
  display: none;
}
.rdt_Pagination div:nth-child(2) {
  display: none;
}
.tabtable.potfolio img {
  width: 30px;
}
/* .tabtable.potfolio .rdt_TableCell {
  min-width: 80px;
} */
.tabtable.potfolio button.iconbtn {
  font-size: 11px;
  text-transform: uppercase;
}
.samewidth{
  min-width: 84px !important;
}
.themeinput input::placeholder {
  color: gray;
}

.dashsec .tabtable.potfolio .rdt_TableCell span.green,
.dashsec .tabtable.potfolio .rdt_TableCell span.gray,
.dashsec .tabtable.potfolio .rdt_TableCell span.red,
.transsec .rdt_TableRow .rdt_TableCell:first-child span{
  /* height: 30px;
  width: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
  border-radius: 3px;
}
.tabtable.potfolio .rdt_TableCell span.red{
  background: #EB4848;
}
.tabtable.potfolio .rdt_TableCell span.green{
  background: #65A819;
}
.tabtable.potfolio .rdt_TableCell span.gray{
  background: #989696;
}
.tabtable.potfolio .fa.fa-arrow-down.green{
  transform: rotate(40deg);
}
.tabtable.potfolio .fa.fa-arrow-down.red{
  transform: rotate(-40deg);
}
.rdt_Pagination {
  background: transparent !important;
  color: #808080 !important;
}
.rdt_Pagination button svg{
  fill: #808080;
}
.btn-filter{
  color: #fff;
  border: 0;
}
.exchange .input-group input{
  background: #2C2B2B;
  border-color: #2C2B2B;
  margin-right: 10px;
  color: #fff;
  height: 45px;
  border-radius: 8px !important;
}
.exchange .input-group button{
  border-radius: 8px !important;

}
.dropdown-toggle img {
  width: 20px;
}
.exchange .dropdown-toggle{
line-height: 2;
}
.exchange .input-group button{
  background: #2C2B2B;
  border-color: #2C2B2B;
  color: #fff;
  font-family: "maka";
}
.exchange .input-group div {
  background: #686868;
  transform: translate(99px, 0px) !important;
  min-width: 100px !important;
}
.exchange .input-group div img {
  width: 17px;
  margin-right: 5px;
}
.exchange .input-group div a{
  color: #fff;
}
.exchange .input-group div a:hover {
  background: #444141;
}
.exbtn {
  width: 50px;
  height: 50px;
  background: #2C2B2B;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(90deg);
}
.exchange .input-group button:focus{
  box-shadow: none !important;
}
.btn-filter:hover, .btn-filter.active{
  color: #000;
  background: var(--themegrad);
}
.profile .profimg {
  height: 155px;
  aspect-ratio: 1/1;
  border-radius: 6px;
}
.profile span.fa.fa-pencil {
  position: absolute;
  bottom: -5px;
  right: -7px;
  color: #000;
  background: #fff;
  font-size: 13px;
  padding: 3px;
  border-radius: 2px;
}
.tabtable .rdt_TableHeadRow, .tabtable .rdt_TableRow,
.tabtable .rdt_Table {
  background: transparent;
  color: #fff;
  border-color:#313131 ;
}
.tabtable .nav-tabs li button{
  border: 0;
  margin-right: 5px;
  color: #fff;
}
.tabtable .nav-tabs li button.active,.tabtable .nav-tabs li button:hover {
  background: linear-gradient(180deg, #8572ff 20%, #8572ff 80%) !important;
  border-radius: 8px;
  border-color: transparent !important;
  color: #fff;
  border:0
}
.tabtable .nav-tabs{    border-bottom: none;}
.tabtable .tab-content::-webkit-scrollbar-track,
.themescroll::-webkit-scrollbar-track, 
.sidebar::-webkit-scrollbar-track {
 background: #302E2E;
  border-radius: 5px;
}
.tabtable .tab-content::-webkit-scrollbar-thumb,
.sidebar::-webkit-scrollbar-thumb,
.themescroll::-webkit-scrollbar-thumb {
   background: #6D6C6C;
  border-radius: 5px;
}
.tabtable .nav-tabs {
  border-bottom: none;
  border-bottom: 1px solid #565656;
  padding: 19px;
}
.tabtable .tab-content::-webkit-scrollbar,
.themescroll::-webkit-scrollbar, .sidebar::-webkit-scrollbar  {
  width: 5px;
  height: 6px;

  
}
.themescroll.hidescroll::-webkit-scrollbar{
  width: 0;
}
.themescroll, .sidebar{
  scrollbar-color: #6D6C6C #302E2E !important;
  scrollbar-width: thin !important;
}


.themeinput button.btn-link,.acquire button.btn-link.small {
  background: gray;
  color: #fff;
  border-radius: 30px !important;
  text-decoration: none;
}
.selectbank > div:first-child {
  background: gray;
  width: 95px;
  height: 94px;
  text-align: center;
  border-radius: 7px;
  padding-top: 17px;
  margin-right: 10px;
}
.selectbank > div:first-child input {
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  height: 69px;
  border-radius: 8px;
  opacity: 0;
}
.selectbank > div input::placeholder{
  color: #fff;
}
.selectbank > div:first-child input:focus, .selectbank > div input:focus-within {
  outline: 0 !important;
}
.selectbank > div input {
  background: gray;
  color: #fff;
  border-radius: 8px;
  border: 0;
  padding: 5px;
  font-size: 14px;
}

.selectbank > div:first-child img {
  height: 30px;
  margin-bottom: 5px;
}
.landing img{
  max-width: 100%;
}
.landhead{
  border-bottom: 2px solid #2e2e2e;
}
.banner h1{
  font-size: 50px;
}
.banner h1 span{
  font-size: 40px;
}
.landhead img {
  height: 39px;
}
.banner .owl-dots {
  margin-top: 40px !important;
}
.bordbottom {
  border-bottom: 1px solid #565656;
}
img.pencil {
  width: 17px;
}
.thememodal .modal-content{
  background-color: #0e0e0e;
  border-radius: 10px;
}
.modal-backdrop.show {
  background: #000000;
  opacity: 0.9;
}
a.navbar-brand img {
  height: 38px;
  padding-left: 15px;
}
.editimg{
  position: relative; margin-right: 15px;
}
.editimg img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
 
}
.editimg button {
  position: absolute;
  background: #5466d1;
  border: none;
  padding: 4px;
  font-size: 10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: 0;
}
.editimg button input{
  width: 25px;
  border-radius: 50%;
  height: 25px;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
}
.themeinput input:focus{
    background: #393939; 
    color: #fff;
}
.themeinput input::placeholder{
  color: #fff;
}
.themeinput label{
  font-size: 14px;
  color: gray;
}
.preference img {
  height: 80px;
  width: 80px;
}
input::placeholder {
  font-size: 15px;
}

.circlebtn{
  background: linear-gradient(180deg, #3750ea 20%, #223291 80%) !important;
  border-radius: 50%;
  color: #fff;
  height: 40px;
  width: 40px;
}
.headicon {
  background: var(--themegrad);
  color: #000;
  height: 26px;
  width: 26px;
  text-align: center;
  padding: 7px;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
}
.headicon span {
position: absolute;
background: #fff;
height: 13px;
width: 13px;
border-radius: 50%;
font-size: 10px;
display: flex;
align-items: center;
justify-content: center;
right: -6px;
top: 0;
}
.circlebtn:hover{
  color: #fff;
}
.history .rdt_TableBody .rdt_TableRow {
  background: transparent;
  border-radius: 5px;
  margin-bottom: 4px;
  margin-top: 4px;
  color: #fff;
}
.history .rdt_Table{
  background: transparent !important;
}
.h-55{
  height:55px
}

.rdt_TableRow .rdt_TableCell {
  color: gray;
}

.plans {
  padding: 15px;
  background: #2e2e2e;
  border-radius: 15px;
}
.bordbox {
  border: 1px solid gray;
}
.bordbox.redbox {
  background: #ff00002b;
  border-color: #780a0a;
}
.bank-det table tr td {
  padding-left: 10px;
  background: #393939;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mobilesidebar ul {
  padding: 0;
}
.mobilesidebar ul li{
  list-style-type: none;
}
.mobilesidebar ul li a{
  padding-left: 0;
}
.mobilesidebar .sidebarlist li a button {
  background: transparent;
  color: #fff;
  border: 0;
}
.bank-det table tr{
  border: 1px solid #000;
}
.form-switch input,.form-switch .form-check-input:focus
.form-switch .form-check-input:checked{
  background-color: #4560ee !important;
  border-color: #4560ee !important;
}
button.regbtn {
  background: #3750e9;
  color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 20px;
  border: 0;
  z-index: 9;
}
.browse{
  position: relative;
  font-size: 14px;
  width: 163px;
}
.browse input{
  width: 50px;
  opacity: 0;
}
.browse button{
  font-size: 14px;
    position: absolute;
    padding: 0;
    right: 0;
pointer-events: none;
}
.fa-cloud-upload{
  font-size: 30px;
  margin: 15px;
}
.loginmodal .modal-dialog-scrollable .modal-content{
  overflow: visible !important;
}
.uploads img {
  width: 56px;
  height: 56px;
  padding: 10px;
  background: #454966;
  border-radius: 5px;
}
.closebtn {
  max-width: 40px;
}
.uploads {
  background: #1d1c1c;
  border-radius: 8px;
  padding: 10px;
}
.closebtn .bg-disabled {
  background: gray !important;
  border: none;
}
.closebtn .bg-white {
  background: #fff !important;
  border: none;
}
.uploadfiles .progress {
  border-radius: 14px;
  height: 6px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.uploadfiles .circlebtn {
  height: 30px;
  width: 30px;
}
span.fa.fa-check.circle {
  font-size: 18px;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 15px;
}
.wizard{
  display: flex;
  justify-content: space-between;
}
.wizard > div{
  flex: 1;
  position: relative;
  z-index: 9;
}
.wizard > div:first-child{
  text-align: left;
}
.wizard > div:last-child{
  text-align: right;
}
.wizard.text-center .circlebtn.disable {
  background: gray !important
}
.prog1:after,.prog2:after,.prog2:before,.prog3:before{
  content: "";
  background: gray;
  height: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.prog1:after,.prog3:before,.prog2:after,.prog2:before{
  margin: auto;
}
.prog2:after,.prog2:before{
  width: 50%;
}
.prog1:after , .prog3:before {
  width: 100%;
}
.prog2:before,.prog3:before{
  left: 0;
}
.prog2:after{
  right: 0;
}
.prog1.progres:after,.prog2.progreshalf:before,.prog2.progresfull:before
,.prog2.progresfull:after,.prog3.progreshalf:before {
  background: #3a55f8;
}
.prog2:after, .prog2:before {
  width: calc(50% - 40px);
}
.prog1:after{
  width: calc(100% - 58px);
  right: 0;
}
.prog3:before{
  width: calc(100% - 58px);
  left: 0;
}
.confirm{
  display: flex;
  gap: 15px;
}
form.confirm input{
  display:inline-block;
  width:50px;
  height:50px;
  text-align:center;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #c8c8c8;
}
form.confirm input.otp:focus{
  box-shadow: none;
}
.landing{
  background-color: #050505;
}
.mobileicons .nav-item.dropdown a:after {
  display: none;
}
.togglebtn span.fa.fa-bars.pe-3 {
  font-size: 21px;
}
.plans table th:nth-child(2),.plans table tr td:nth-child(2) {
  background: #3a56f8;
}
.plans table th:nth-child(2),.plans table th:nth-child(3),
.plans table th:nth-child(4){
  border-radius: 15px 15px 0 0;
}
.plans table th:nth-child(3),.plans table tr td:nth-child(3),
 .plans table th:nth-child(4),.plans table tr td:nth-child(4) {

  background: #363636;
}
.plans table th:nth-child(3),.plans table tr td:nth-child(3),
 .plans table th:nth-child(4),.plans table tr td:nth-child(4),
 .plans table th:nth-child(2),.plans table tr td:nth-child(2){
  min-width: 200px;
  text-align: center;
 }
 .plans table tbody tr:first-child td:nth-child(1) {
  min-width: 219px;
}
.plans table{
  border-spacing: 44px 0;
  border-collapse: separate !important;

}
.plans table tr:last-child td:nth-child(2),
.plans table tr:last-child td:nth-child(3),
.plans table tr:last-child td:nth-child(4){
  border-radius: 0 0 15px 15px;
}
.plans tbody tr:first-child td:first-child {
  color: gray;
  font-weight: 600;
  font-size: 20px;
}
.plans tr td {
  font-size: 18px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.plans table tbody tr:first-child td:nth-child(2), .plans table tbody tr:first-child td:nth-child(3), .plans table tbody tr:first-child td:nth-child(4) {
  font-size: 22px;
  font-weight: 500;
}
.landing button.owl-dot span{
  height: 8px !important;
  width: 8px !important;
}
.landing button.owl-dot.active span {
  background: #3a56f8 !important;
  width: 57px !important;
}
.statebox{
  background: #141414;
}
.status h4 {
  font-size: 40px;
}
.loginmodal{
  cursor: url(./Assets/Images/close.png), auto
}
button.btn.close {
  font-size: 24px;
  position: absolute;
  right: 11px;
  top: 8px;
  z-index: 999;
  background: #303030;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 1.8;
}
button.btn.close span{
  height: 44px;
  width: 28px;
}
.editfile input {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}
.editfile span.fa.fa-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #957bfe;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  line-height: 2;
  font-size: 13px;
  pointer-events: none;
}
.editfile {
  width: 100px;
  height: 100px;
}
.confirm .otp {
  background: #000;
  border-color: gray;
  color:#fff
}
.loginmodal .modal-content{
  cursor: auto;
}
.donutchart{
  height: 401px !important;
}
.donutchart text{
  fill: #fff;
}
@media(max-width:1199px){
  .tabtable .rdt_TableCell, .tabtable .rdt_TableCol_Sortable {
    font-size: 16px !important;
  }
  .plans tr td {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
 }
  /* .plans table th:nth-child(3), .plans table tr td:nth-child(3), 
  .plans table th:nth-child(4), .plans table tr td:nth-child(4), 
  .plans table th:nth-child(2), .plans table tr td:nth-child(2) {
    width: 170px;
  }
  */
}
.showin767{
  display: none;
}
@media(max-width:1199px){
  .redeem img.chair {
    max-height: 200px !important;
  }
}
@media(max-width: 991px){
  .redeem img.chair {
    max-height: 200px !important;
}
.redeem img.chair {
  max-height: 200px !important;
}
  .page .sidebar, .navbar-brand {
    /* width: 180px; */
  }
  .page .content {
    /* padding-left: 180px; */
  }
  .status h4 {
    font-size: 30px;
  }
  .banner h1 {
    font-size: 38px;
  }
  .banner h1 span {
    font-size: 30px;
  }
  .banner p,.zarc p,.zarctech p, .zarcaims p{
    font-size: 14px;
  }
}
@media(max-width: 767px){
  .hidein767{
    display: none;
  }
  .sidebarlist {
    height: calc(100vh - 96px);
    overflow-y: scroll;
}
.sidebarlist li {
  max-width: 200px;
}
  .logout {
    display: none;
}
  .page .content {
    padding-left: 0px;
  }
  .content.redeem {
    padding-top: 20px;
}
  .page {
    padding-top: 0px;
}
  .exchange .input-group div{
    transform: translate3d(0px, 48px, 0px) !important;
  }
  .transscroll{
    width: 100%;
    text-align: right;
    overflow: scroll;
    white-space: nowrap;
    margin-left: 17px;
  }
  .h-55.navbar {
    height: 77px;
}
  .showin767{
    display: block;
  }
  .mobileicons{
    display: flex !important;
    align-items: center;
  }
  .banner h1 {
    font-size: 30px;
}
  img.sideimage {
    height: 280px;
    width: auto !important;
}
  .hidehead{
    display: none;
  }
  .showhead{
    display: block;
  }
  .status h4 {
    font-size: 24px;
   }
   .status p {
    font-size: 14px;
   }
  .logobg{
    background: transparent !important;
    border-right: 0;
  }
  .box {
     aspect-ratio: unset; 
  }
  
  .sidebarlist li a.active{
    border-radius: 0;
  }
  .dnone-767 {
    display: none;
}
  .navbar-brand{
    width: unset;
  }
  .togglebtn {
    background: transparent;
    border: none;
    color: #fff;
    padding: 0;
  }
  .mobilesidebar .sidebarlist li a button {
    background: transparent;
    color: #fff;
    border: 0;
  }
  .navbar-dark .navbar-brand {
    background: #212529;
}
  .navbar-brand img {
    height: 37px;
}
  .page .sidebar {
    display: none;
  }
  .page .content {
    width: 100%;
  }
  .mobilemenu{
    position: absolute;
    min-height: 100vh;
    top: 54px;
    width: 100%;
    z-index: 999;
    background: #000;
    padding: 0 20px;
  }

.mobilemenu .dropdown-menu {
    background: #000;
}
.mobilemenu .dropdown a{
  color: #fff !important;
}
}


@media(max-width:575px){
  .btn.btn-theme {
    font-size: 14px;
  }
.upload input[type="text"] {
  height: 35px;
}
.selectbank > div:first-child {
  height: 79px;
  padding-top: 12px;
  margin-right: 10px;
}

  .tablescroll {
    height: 73%;
}
  .trans .d-flex h3.headfont {
    font-size: 16px;
}

  .nav.nav-tabs li.nav-item button {
    font-size: 12px;
}
  .redeem img.chair {
    max-height: 140px !important;
}
  .redeem img {
    max-height: 95px;
  }
  .tab-content .mw-300{
    max-width: 90% !important;
  }
  g.donutchart-legend{
    display: none;
  }
  .landhead img {
    height: 33px;
}
  .status h4 {
    font-size: 20px;
  }
  .uploads img {
    width: 40px;
    height: 40px;
    padding: 6px;
  }
  
  button.regbtn {
    right: 7px;
  }
  .wizard button.btn.circlebtn {
    height: 25px;
    width: 25px;
    line-height: 1;
    font-size: 12px;
    padding: 0;
  }
  .prog1:after, .prog2:after, .prog2:before, .prog3:before {
    height: 3px;
  }
  .prog1:after,.prog3:before  {
    width: calc(100% - 36px);
  }
  .prog2:after, .prog2:before {
    width: calc(50% - 22px);
  }



}


@media(max-width:460px){
  .chartbox canvas {
    height: 230px !important;
    width: 230px !important;
}
}
@media(max-width:425px){
  .tab-content .mw-300 {
    max-width: 96% !important;
    font-size: 14px;
}
.rightinput .input-group button {
  margin-left: 5px !important;
}
  img.sideimage {
    height: 200px;
  }

  .navbar-brand img {
    height: 30px !important;
  }
  form.confirm input {
    width: 42px;
    height: 42px;
}
.confirm {
  display: flex;
  gap: 3px;
}

}
@media(min-width:757px){
  
  .modal-sm {
    max-width: 375px;
  }
  
}

.transsec .rdt_TableRow .rdt_TableCell:last-child div:first-child {
  overflow: visible !important;
  white-space: break-space;
}


.history .dropdown button:after {
  display: none;
}
.history .dropdown button span.fa.fa-ellipsis-v{
  transform: rotate(90deg);
  /* color: #65A819 !important; */
  /* height: 21px !important;
    width: 35px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.history .dropdown button.btn-success span.fa.fa-ellipsis-v{
 color: #65A819 !important
}
.history .dropdown button{
  background-color: #273813;
  border-color: #273813;
}
.history .dropdown button:focus {
  box-shadow: none !important;
  background-color: #273813;
}
.history .dropdown button.red span {
  color: #eb4848 !important;
}
.history .dropdown button.red {
  background-color: #4a2020;
  border-color: #4a2020;
}
.history .dropdown button.gray span {
  color: #989696 !important;
}
.history .dropdown button.gray {
  background-color: #343434;
  border-color: #343434;
}

.history .dropdown > div a {
  font-size: 14px;color: #fff;
  text-decoration: none;
  padding-left: 40px;
}
.history .dropdown > div a:hover{
  background-color: transparent;
  color: #9cc7f5;
}
.notify.dropdown > div{
  background: #202020;
  
}
.sidebarlist .dropdown a.active {
  color: #9cc7f5;
}
.mobileicons div{
  /* display: flex; */
    align-items: center;
}
.mobileicons div a{
  color: #fff !important;
  padding-left: 3px;
}
.sidebarlist{
  padding-left: 12px;
}
.notify .graytxt{
  color: rgba(255,255,255,.55);

}
a:hover .fa-sign-out {
  color: #9b80fd;
}
.notify.dropdown .dropdown-menu{
 padding: 5px;
 
}
.notify .dropdown a:hover {
  background: #0a0a0a !important;
}

/* admin */


/* .transsec div[data-column-id="1"] {
  min-width: 180px;
}
.transsec div[data-column-id="4"] {
  min-width: 220px;
} */
.sidebarlist .show.dropdown div,.sidebarlist .dropdown-menu.show {
  position: relative !important;
  transform:unset !important;
  margin-right: 15px !important;
}
.dropdown-menu.show {
  /* padding:  0 !important; */
  background-color: transparent;
}
.dropdown button.dropdown-toggle
{
  padding-left: 5px !important;
  height: 50px !important;
}
.dropdown-menu.show .dropdown
{
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.dropdown-menu.show 
{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.sidebarlist li .dropdown {
  /* background: #1a1a1a !important; */
  border-color: #1a1a1a !important;
}
.dropdown .dropdown-menu{
  border: 0 !important;
 
}
.sidebarlist .dropdown  a{
  padding-left: 10px;
  color: #fff ;
  text-decoration: none;
}
.sidebarlist .dropdown  a:hover{
  color: #9cc7f5 ;
}
.history .dropdown > div a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding-left: 20px;
}
.iconbtn {
  max-width: 26px;
  padding: 7px !important;
  line-height: 1;
  color: #fff;
  border-radius: 3px !important;
}
.iconbtn.btn span {
  color: #fff;
}

.custswitch input{
  background-color: #957bfe  !important;
  /* background-image: linear-gradient(90deg, #91d3f3 28% , #c49efb 64%,#957bfe 86%) !important; */
}

.usertabs #cell-4-undefined ,
.usertabs #cell-3-undefined ,
.contacttabs #cell-2-undefined ,
.contacttabs #cell-3-undefined ,
.contacttabs #cell-6-undefined ,
.deposittabs  #cell-3-undefined
{
  word-break:keep-all !important
}

.themefile img {
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin-bottom: 18px;
}
button.chooseimg {
  background: #2c2b2b;
  color: #fff;
}
.themefiles{
  position: relative;
}
.themefiles input {
  position: absolute;
  width: 106px;
  left: 0;
  top: 0 !important;
  height: 37px !important;
  margin: 0 !important;
  opacity: 0;
}
.nofocus:focus{
  box-shadow: none !important;
}

.logo_resize
{
  max-width: 185px;
}
@media only screen and (max-width:575px)
{
  .logo_resize
  {
    max-width: 185px !important;
  }
}
.card.card_cutout div[role="table"] {
  background: transparent;
}
.documrnt_flex
{
  display: flex;
  /* align-items: center; */
}
.btn_upload_admin
{
  margin-left: 10px;
  
}
.doc_link
{
  color:#957bfe !important;
}
.doc_link:hover
{
  color:#91d3f3 !important;
}
.themeinput_table .rdt_TableRow
{
margin-top: 7.5px;
margin-bottom: 7.5px;
}
.themeinput_table .rdt_TableBody
{
  margin-top: 15px;
}
.btn_input_new
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.themeinput_table .rdt_TableHeadRow .rdt_TableCol:last-child,
.themeinput_table .rdt_TableBody .rdt_TableCell:last-child

{
min-width: 450px !important;
}
.themeinput_table .rdt_TableBody .rdt_TableCell:last-child>div
{
width: 100%;
}
.themeinput_table .rdt_TableBody .rdt_TableCell:last-child>div .form-control
{
  width: 70%;
}
.themeinput_table .rdt_TableBody
{
  margin-bottom: 30px;
}
.word_break_text
{
  word-break: break-all;
}
.depositmodal_textarea:focus,.depositmodal_textarea:focus-visible{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

}
.email_braek_td
{
  word-break: break-all;
}